import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILabel } from 'src/models/general';
import { IAdvanceSearchOnm } from 'src/models/onlineMedia';

export interface IAdvanceSearchOfflineMedia
  extends Omit<IAdvanceSearchOnm, 'excludeMediaList'> {
  phrases: string[];
  labelObjectList: ILabel[];
}

export const initialState: IAdvanceSearchOfflineMedia = {
  sentiment: [],
  validationList: [],
  mediaList: [],
  includeKeywords: [],
  excludeKeywords: [],
  searchType: 'allSearch',
  labelList: [],
  phrases: [],
  labelObjectList: []
};

export const advanceSearchOfflineMedia = createSlice({
  name: 'advanceSearchOfflineMedia',
  initialState,
  reducers: {
    reducerUpdateAdvanceSearchOfflineMediaPhrases: (
      state: IAdvanceSearchOfflineMedia,
      action: PayloadAction<string[]>
    ) => {
      state.phrases = action.payload;
    },
    reducerUpdateAdvanceSearchOfm: (
      state: IAdvanceSearchOfflineMedia,
      action: PayloadAction<Partial<IAdvanceSearchOfflineMedia>>
    ) => {
      state.mediaList = action.payload.mediaList;
      state.searchType = action.payload.searchType;
      state.includeKeywords = action.payload.includeKeywords;
      state.excludeKeywords = action.payload.excludeKeywords;
      state.sentiment = action.payload.sentiment;
      state.validationList = action.payload.validationList;
      state.labelList = action.payload.labelList;
      state.labelObjectList = action.payload.labelObjectList;
    },
    reducerUpdateAdvanceSearchOfmMediaList: (
      state: IAdvanceSearchOfflineMedia,
      action: PayloadAction<string[]>
    ) => {
      state.mediaList = action.payload;
    }
  }
});

export const {
  reducerUpdateAdvanceSearchOfflineMediaPhrases,
  reducerUpdateAdvanceSearchOfm,
  reducerUpdateAdvanceSearchOfmMediaList
} = advanceSearchOfflineMedia.actions;

export default advanceSearchOfflineMedia.reducer;
